import React, { useState, useRef, useCallback, useEffect } from "react";
import { pipe } from "fp-ts/lib/pipeable";

import { styled } from "@styles/theme";
import { breakpoints } from "@styles/responsive";

import { Text } from "../Text";
import { Box } from "../ResponsiveBox";

interface StyleProps {
  error?: boolean;
}

const Wrapper = styled.div<StyleProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & input {
    max-width: 14%;
    font-size: 60px;
    text-align: center;
    color: ${(p) => p.theme.colors.primary[500]};
    border: none;
    border-bottom: 1px solid
      ${(p) => p.theme.colors[p.error ? "red" : "neutral"][400]};
    border-radius: 0;

    @media (max-width: ${breakpoints[0]}px) {
      font-size: 40px;
      max-width: 50px;
      min-width: 30px;
    }
  }
`;

enum KEYS {
  left = 37,
  up = 38,
  right = 39,
  down = 4,
  backspace = 8,
}

const initialValue = Array(6)
  .fill(null)
  .map((_, i) => ({ id: i, value: "" }));

const initialRefs = Array(6)
  .fill(null)
  .map(() => React.createRef<HTMLInputElement>());

export interface Props {
  error?: string;
  onChange: (value: string) => void;
}

export const CodeInput: React.FC<Props> = ({ onChange, error: errorProp }) => {
  const [error, setError] = useState("");
  const [values, setValues] = useState(initialValue);
  // @TODO: remove this any
  const inputRefs = useRef<any>(initialRefs);
  const [backspacePressed, setBackspacePressed] = useState(false);

  const onlyNumbers = (text: string) =>
    text.split("").every((c) => Number(c) >= 0 && Number(c) <= 9) ? text : "";

  const validLength = (text: string) => (text.length === 6 ? text : false);

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();

    const paste: string = (
      e.clipboardData || (window as any).clipboardData
    ).getData("text");

    const validPaste = pipe(paste, onlyNumbers, validLength);

    if (validPaste) {
      const newValues = validPaste.split("").map((value, index) => ({
        value: value || "",
        id: index,
      }));

      setValues(newValues);
      inputRefs.current[5].current.focus();
    } else {
      setError("Only numeric characters allowed");
    }
  };

  useEffect(() => {
    if (errorProp !== error) setError(errorProp || "");
  }, [errorProp]);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleChange = useCallback(
    (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      // Pressing CMD V makes the data equals null, and value equals the copied characters
      let { data } = e.nativeEvent; // Always the key pressed
      const { value } = e.target; // Always the characters in the input

      // When erasing a field
      if (value === "" && data === null) {
        data = "";
      }

      if (isNaN(Number(data))) {
        data = null;
      }

      if (data !== undefined && data !== null) {
        const update = [...values];
        const next = inputRefs.current[index + 1];
        update[index] = { ...values[index], value: data };
        setValues(update);
        if (data) next?.current.focus();
      } else if (value) {
        // This if happens when pressing cmd V
        const update = [...values];
        const newValues = value
          .split("")
          .slice(0, 6 - index)
          .filter((s) => !isNaN(Number(s)));

        newValues.forEach((value, i) => {
          update[index + i] = { ...values[index + i], value };
        });

        setValues(update);

        const next = inputRefs.current[index + newValues.length - 1];
        next?.current.focus();
      }
    },
    [values, setValues],
  );

  useEffect(() => {
    const stringValue = values.reduce((acc, { value }) => acc + value, "");
    onChange(stringValue);
  }, [values]);

  const handleKeyUp = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      const next = inputRefs.current[index + 1];
      const previous = inputRefs.current[index - 1];

      switch (e.keyCode) {
        case KEYS.backspace:
          if (backspacePressed) {
            setBackspacePressed(false);
            break;
          }
        case KEYS.left:
          previous?.current.focus();
          break;
        case KEYS.right:
          next?.current.focus();
      }
    },
    [inputRefs, handleChange, backspacePressed],
  );

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      // setError("");
      switch (e.keyCode) {
        case KEYS.backspace:
          if (inputRefs.current[index].current.value.length > 0)
            setBackspacePressed(true);
      }
    },
    [inputRefs, handleChange],
  );

  return (
    <div>
      <Wrapper error={Boolean(error)}>
        {values.map(({ value, id }, index) => (
          <input
            style={{ fontFamily: "Nunito Sans, sans-serif" }}
            min={0}
            max={9}
            size={1}
            type="tel"
            pattern="[0-9]{1}"
            key={id}
            ref={inputRefs.current[index]}
            value={value}
            onKeyUp={(e) => handleKeyUp(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onChange={(e) => handleChange(index, e)}
          />
        ))}
      </Wrapper>
      {error && (
        <Box pT="lg">
          <Text color="red" colorWeight="700" size={12} align="center">
            {error}
          </Text>
        </Box>
      )}
    </div>
  );
};
