import { useEffect } from "react";

type Cb = () => Cleaner | undefined | void;
type Cleaner = undefined | (() => void | undefined);

export const useTimeoutEffect = (
  cb: Cb,
  timeout: number,
  deps: ReadonlyArray<any> = [],
) => {
  useEffect(() => {
    let cleaner: Cleaner;

    const token = setTimeout(() => {
      const res = cb();
      if (res) cleaner = res;
    }, timeout);

    return () => {
      clearTimeout(token);
      typeof cleaner === "function" ? cleaner() : null;
    };
  }, [cb, ...deps]);
};
